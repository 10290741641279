const ScrollManager = {
  containerSelector: '.container',

  saveScrollPosition() {
    sessionStorage.setItem('scrollPosition', document.querySelector(this.containerSelector).scrollTop);
  },

  restoreScrollPosition() {
    const savedPosition = sessionStorage.getItem('scrollPosition');
    if (savedPosition !== null) {
      document.querySelector(this.containerSelector).scrollTop = parseInt(savedPosition, 10);
      sessionStorage.removeItem('scrollPosition');
    } else {
      document.querySelector(this.containerSelector).scrollTop = 0;
    }
  },

  scrollToTop() {
    document.querySelector(this.containerSelector).scrollTop = 0;
  },

  init() {
    if ('scrollRestoration' in history) {
      history.scrollRestoration = 'manual';
    }

    if (typeof Turbolinks !== 'undefined') {
      Turbolinks.scroll = {};

      document.addEventListener('turbolinks:before-visit', this.saveScrollPosition.bind(this));
      document.addEventListener('turbolinks:load', this.restoreScrollPosition.bind(this));
    } else {
      window.addEventListener('load', this.restoreScrollPosition.bind(this));
    }

    window.addEventListener('beforeunload', this.saveScrollPosition.bind(this));

    window.addEventListener('popstate', (event) => {
      if (event.state && event.state.turbolinks) {
        this.restoreScrollPosition();
      }
    });

    document.addEventListener('click', (event) => {
      const link = event.target.closest('a');
      if (!link) return;

      if (link.getAttribute('data-remote') === 'true') return;
      if (link.closest('.link')) return;

      const href = link.getAttribute('href');
      if (!href) return; 

      let url;
      try {
        url = new URL(href, window.location.origin);
      } catch (e) {
        return; 
      }

      const isSameOrigin = url.origin === window.location.origin;
      const isAnchorLink = url.hash && isSameOrigin && url.pathname === window.location.pathname;

      if (isAnchorLink) {
        event.preventDefault();
        const target = document.querySelector(url.hash);
        if (target) {
          target.scrollIntoView({ behavior: 'smooth' });
          history.pushState(null, '', url.hash);
        }
      } else if (isSameOrigin && !link.dataset.turbolinks && typeof Turbolinks !== 'undefined') {
        event.preventDefault();
        this.saveScrollPosition();
        Turbolinks.visit(url.href);
      }
    });
  }
};

document.addEventListener('DOMContentLoaded', () => {
  ScrollManager.init();
});

export { ScrollManager };