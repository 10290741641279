(function() {

  const resizeTextarea = function() {
    const originalHeight = $("#chat-text").height();

    $("#chat-text").on('input', function () {
      this.style.height = 'auto';
      this.style.height = (this.scrollHeight) + 'px';
      $(this).css('overflow-y', 'auto');
    });

    $("#msg-form").submit(function(e) {
      e.preventDefault();
      $("#chat-text").css('height', originalHeight);
      $("#chat-text").css('overflow-y', 'hidden'); 
    });

  }

  $(document).on('turbolinks:load', resizeTextarea);
})();
