import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["linkUrl"]

  share(event) {
    event.preventDefault()
    const url = this.linkUrlTarget.dataset.url

    if (navigator.clipboard) {
      navigator.clipboard.writeText(url)
        .then(() => {
          console.log("Link copied to clipboard!");
        })
        .catch(() => {
          console.log("Failed to copy link to clipboard.");
        });
    } else {
      clipboard.writeText(url)
        .then(() => {
          console.log("Link copied to clipboard!");
        })
        .catch(() => {
          console.log("Failed to copy link to clipboard.");
        });
    }
  }

  showToast(type, message) {
    toastr[type](message)
  }
}