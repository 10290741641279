import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["entries", "pagination"]
  static values = { total: Number }

  connect() {
    this.loading = false;
  }

  loadMore(event) {
    event.preventDefault();
    
    if (this.loading) return;
  
    let next_page = this.paginationTarget.querySelector("a[rel='next']");
    if (!next_page) {
      return;
    }
  
    this.loading = true;
    let url = next_page.href;
  
    Rails.ajax({
      url: url,
      type: 'GET',
      dataType: 'json',
      success: (data) => {
        this.entriesTarget.insertAdjacentHTML('beforeend', data.entries);
        this.updatePagination(data.pagination);
        this.loading = false;
        this.updateLoadMoreButton();
      },
      error: (error) => {
        console.log("Error loading more comments:", error);
        this.loading = false;
      }
    });
  }
  
  updateLoadMoreButton() {
    const loadedComments = this.entriesTarget.children.length;
    const remainingComments = parseInt(this.totalValue, 10) - loadedComments;
  
    const buttonText = `${remainingComments} more replies`;
    document.getElementById('load-more-comments').innerText = buttonText;
  }

  updateLoadMoreButton() {
    const loadedComments = this.entriesTarget.children.length;
    const remainingComments = parseInt(this.totalValue, 10) - loadedComments;

    if (remainingComments > 0) {
      const buttonText = `${remainingComments} more replies`;
      document.getElementById('load-more-comments').innerText = buttonText;
      document.getElementById('load-more-comments').style.display = 'block';
    } else {
      document.getElementById('load-more-comments').style.display = 'none'; 
    }
  }
  

  updatePagination(paginationHtml) {
    this.paginationTarget.innerHTML = paginationHtml;
    if (!this.paginationTarget.querySelector("a[rel='next']")) {
      document.getElementById('load-more-comments').style.display = 'none';
    }
  }
}
