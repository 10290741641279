import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './components/App';
import AppCategories from './components/AppCategories';

let root = null;
let rootCategories = null;

function renderApps() {
  const countriesContainer = document.getElementById('submit-to-options');
  const categoriesContainer = document.getElementById('submit-to-categories');

  if (countriesContainer) {
    if (root) {
      root.unmount();
    }

    root = createRoot(countriesContainer);
    root.render(<App />);
  } else {
    console.log('Container for App not found');
  }

  if (categoriesContainer) {
    if (rootCategories) {
      rootCategories.unmount();
    }
    rootCategories = createRoot(categoriesContainer);
    rootCategories.render(<AppCategories />);
  } else {
    console.log('Container for AppCategories not found');
  }
}

function initializeReactApps() {
  document.addEventListener('DOMContentLoaded', () => {
    renderApps();
  });

  document.addEventListener('turbolinks:load', () => {
    renderApps();
  });

  document.addEventListener('turbolinks:before-cache', () => {
    if (root) {
      root.unmount();
      root = null;
    }
    if (rootCategories) {
      rootCategories.unmount();
      rootCategories = null;
    }
  });
}

export default initializeReactApps;