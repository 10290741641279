function isTargetDeviceSize() {
  return window.matchMedia('(max-width: 1200px)').matches;
}

document.addEventListener("turbolinks:load", function() {
  setupMainElementClicks();
  setupClickableElements();
  removeClickEffects();
});

function handleMainElementClick(element, event) {
  const excludedSelectors = ["a", ".comment-tools", ".dropdown-content", ".details-left", ".mobile.social-share-reveal", ".social-group .mobile-layout"];
  
  if (excludedSelectors.some(selector => event.target.closest(selector))) {
    console.log("Clicked on excluded element, not redirecting");
    return;
  }

  event.preventDefault();
  event.stopPropagation();

  const url = element.dataset.url;
  console.log("Attempting to redirect to:", url);

  if (url) {
    if (isTargetDeviceSize()) {
      element.classList.add('click-effect');
    }

    // Use a temporary link for navigation
    setTimeout(() => {
      const tempLink = document.createElement('a');
      tempLink.href = url;
      tempLink.setAttribute('data-turbolinks', 'true');
      document.body.appendChild(tempLink);
      tempLink.click();
      document.body.removeChild(tempLink);
    }, 150); // Delay for visual effect
  } else {
    console.error("No URL found in data-url attribute");
  }
}

function setupMainElementClicks() {
  document.body.addEventListener('click', function(event) {
    const mainElement = event.target.closest('.link');
    if (mainElement) {
      handleMainElementClick(mainElement, event);
    }
  });
}


function setupClickableElements() {
  const clickableElements = document.querySelectorAll(".comment-tools");

  clickableElements.forEach(function(element) {
    element.addEventListener("click", function(event) {
      if (event.target.closest('.dropdown-content')) {
        return;
      }
      
      // Close all other dropdowns
      clickableElements.forEach(function(otherElement) {
        if (otherElement !== element) {
          const otherDropdown = otherElement.querySelector('.dropdown-content');
          if (otherDropdown) {
            otherDropdown.style.display = 'none';
          }
        }
      });

      const dropdown = element.querySelector('.dropdown-content');
      if (dropdown) {
        dropdown.style.display = dropdown.style.display === 'none' ? 'block' : 'none';
      }
 
      event.stopPropagation();
    });
  });

  // Close dropdowns when clicking outside
  document.addEventListener('click', function(event) {
    if (!event.target.closest('.dropdown-content')) {
      clickableElements.forEach(function(element) {
        const dropdown = element.querySelector('.dropdown-content');
        if (dropdown) {
          dropdown.style.display = 'none';
        }
      });
    }
  });
}

function removeClickEffects() {
  if (isTargetDeviceSize) {
    document.querySelectorAll(".link").forEach(function(element) {
      element.classList.remove('click-effect');
    });
  }
}

document.addEventListener("turbolinks:before-cache", removeClickEffects);
