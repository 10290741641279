(function() {
  const ready = function() {
    $( ".image-wrapper img" ).on('error', function() {
      $(this).css("display", "none");
      $(this).parent().addClass("image-box");
      $(".image-box").html("<div class='chain-link-image'><i class='fas fa-link chain-link-icon'/></div>");
    });
  }

  $(document).on('turbolinks:load', ready);
})();