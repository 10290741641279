(function() {

  const hideSearchBox = function() {
    $(".mobile-search").css('display', 'none');
    $(".mobile-top-nav").show();
  }

  const openSearchBox = function() {
    $(".mobile-search").css('display', 'flex');
    $(".mobile-top-nav").hide();
  }

  const closeSearchBox = function() {
    $(".mobile-search").hide();
    $(".mobile-top-nav").show();
  }

  $(document).on("click", '.magnifying-glass', openSearchBox);
  $(document).on("click", '.mobile-search .back-arrow', closeSearchBox);
  $(document).on("click", '.columned-center', hideSearchBox);
})();