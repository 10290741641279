import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axios from 'axios';
import api from '../api/api';

function AppCategories() {

  const COUNTRY_REGEX = /a\/([a-zA-Z-]+)\/?/; 

  const [submitCategories, setSubmitCategories] = useState([]);
  const [categoryId, setCategoryId] = useState('');
  const [catNameAttr, setCatNameAttr] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);



  function countryFromPath(path) {
    var match = COUNTRY_REGEX.exec(path);
    if (match) {
      return match[1];
    }
    return '';
  }

  const countryName = countryFromPath(location.pathname);

  const getSubforumCategories = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${api.subforumCategories}/${countryName}/subforum-categories.json`);
      setSubmitCategories(response.data);
    } catch (err) {
      setError('Failed to fetch subforum categories');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const getCategories = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${api.categories}.json`);
      setSubmitCategories(response.data);
    } catch (err) {
      setError('Failed to fetch categories');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }; 

  useEffect(() => { 
    if (countryName) {
      getSubforumCategories();
    } else if (location.pathname === '/submit') {
      getCategories();
    }

    setCategoryId("link_sub_forum_category_id")
    setCatNameAttr("link[sub_forum_category_id]")
  }, [countryName]);

  const submitOptions = submitCategories.map((cat) => {
    return {value: cat.id, label: cat.name}
  })

  if (isLoading) {
    return (
      <div className="spinner-wrapper">
        <div id="spinner" className="spinner"></div>
      </div>
    );
  }

  return (
    <Select  
      options={submitOptions}
      id={categoryId}
      name={catNameAttr}
      placeholder="Choose a category"
      className="react-select-submit-options"
      classNamePrefix="react-select"
    />
  );
}

export default AppCategories;