import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["entries", "pagination", "spinner"]

  connect() {
    console.log("InfiniteScroll controller connected");
    this.loading = false;
    window.addEventListener('scroll', () => this.scroll());
  }
  
  disconnect() {  
    window.removeEventListener('scroll', () => this.scroll());
  }
  
  scroll() {
    let next_page = this.paginationTarget.querySelector("a[rel='next']");
    if (!next_page || this.loading) return;

    this.showSpinner(); // Show spinner when loading starts

    let url = next_page.href;
    let body = document.body;
    let html = document.documentElement;
    let height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);

    if (window.pageYOffset > height - window.innerHeight - 100) {
      this.loading = true;
      Rails.ajax({
        url: url,
        type: 'GET',
        dataType: 'json',
        success: (data) => {
          this.entriesTarget.insertAdjacentHTML('beforeend', data.entries);
          this.updatePagination(data.pagination);
          this.hideSpinner(); // Hide spinner after content is loaded
          this.loading = false;
        }
      });
    }
  }

  updatePagination(paginationHtml) {
    this.paginationTarget.innerHTML = paginationHtml;
    if (!this.paginationTarget.querySelector("a[rel='next']")) {
      console.log("Reached the last page");
    }
  }

  showSpinner() {
    this.spinnerTarget.style.display = 'block';
  }

  hideSpinner() {
    this.spinnerTarget.style.display = 'none';
  }
}
