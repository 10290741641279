import { isProduction } from '../utils/environment';

const productionSubforums = {
  Africa: { id: 369 },
  Algeria: { id: 157 },
  Angola: { id: 158 },
  Benin: { id: 159 },
  Botswana: { id: 160 },
  'Burkina-faso': { id: 161 },
  Burundi: { id: 162 },
  Cameroon: { id: 163 },
  'Cape-verde': { id: 164 },
  Car: { id: 165 },
  Chad: { id: 166 },
  Comoros: { id: 167 },
  'Congo-brazzaville': { id: 169 },  
  'Congo-kinshasa': { id: 170 },
  Djibouti: { id: 168 },
  Egypt: { id: 171 },
  Eritrea: { id: 209 },
  'Equatorial-guinea': { id: 210 },
  Ethiopia: { id: 172 },
  Gabon: { id: 173 },
  Gambia: { id: 174 },
  Ghana: { id: 175 },
  Guinea: { id: 177 },
  'Guinea-bissau': { id: 176 },
  'Ivory-coast': { id: 178 },
  Kenya: { id: 179 },
  Lesotho: { id: 180 },
  Liberia: { id: 181 },
  Libya: { id: 182 },
  Madagascar: { id: 183 },
  Malawi: { id: 184 },
  Mali: { id: 185 },
  Mauritania: { id: 186 },
  Mauritius: { id: 187 },
  Morocco: { id: 188 },
  Mozambique: { id: 189 },
  Namibia: { id: 190 },
  Niger: { id: 191 },
  Nigeria: { id: 192 },
  Other: { id: 368 },
  Rwanda: { id: 193 },
  Senegal: { id: 194 },
  'Sierra-leone': { id: 195 },
  Somalia: { id: 196 },
  Somaliland: { id: 197 },
  'South-africa': { id: 198 },
  'South-sudan': { id: 199},
  Sudan: { id: 200 },
  Swaziland: { id: 201 },
  Tanzania: { id: 202 },
  Togo: { id: 203 },
  Tunisia: { id: 204 },
  Uganda: { id: 205 },  
  'Western-sahara': { id: 206 },
  Zambia: { id: 207 },
  Zimbabwe: { id:208 },
};

const developmentSubforums = {
  Africa: { id: 54 },
  Algeria: { id: 1 },
  Angola: { id: 2 },
  Benin: { id: 3},
  Botswana: { id: 4 },
  'Burkina-faso': { id: 5 },
  Burundi: { id: 6 },
  Cameroon: { id: 7 },
  'Cape-verde': { id: 8 },
  Car: { id: 9 },
  Chad: { id: 10 },
  Comoros: { id: 11 },
  'Congo-brazzaville': { id: 13 },
  'Congo-kinshasa': { id: 14 },
  Djibouti: { id: 12 },
  Egypt: { id: 15 },
  Ethiopia: { id: 16},
  Gabon: { id: 17 },
  Gambia: { id: 18 },
  Ghana: { id: 19},
  Guinea: { id: 21 },
  'Guinea-bissau': { id: 20 },
  'Ivory-coast': { id: 22 },
  Kenya: { id: 23 },
  Lesotho: { id: 24 },
  Liberia: { id: 25 },
  Libya: { id: 26 },
  Madagascar: { id: 27 },
  Malawi: { id: 28 },
  Mali: { id: 29 },
  Mauritania: { id: 30 },
  Mauritius: { id: 31 },
  Morocco: { id: 32 },
  Mozambique: { id: 33 },
  Namibia: { id: 34 },
  Niger: { id: 35 },
  Nigeria: { id: 36 },
  Other: { id: 53 },
  Rwanda: { id: 37 },
  Senegal: { id: 38 },
  'Sierra-leone': { id: 39 },
  Somalia: { id: 40 },
  Somaliland: { id: 41 },
  'South-africa': { id: 42 },
  'South-sudan': { id: 43 },
  Sudan: { id: 44 },
  Swaziland: { id: 45 },
  Tanzania: { id: 46 },
  Togo: { id: 47 },
  Tunisia: { id: 48 },
  Uganda: { id: 49 },  
  'Western-sahara': { id: 50 },
  Zambia: { id: 51 },
  Zimbabwe: { id:52 },
};

const subforums = isProduction() ? productionSubforums : developmentSubforums;

const submitOptions = [
 'Africa',
 'Algeria',
 'Angola',
 'Benin',
 'Botswana',
 'Burkina Faso',
 'Burundi',
 'Cameroon',
 'Cape Verde',
 'CAR',
 'Chad',
 'Comoros',
 'Congo-Brazzaville',
 'Congo-Kinshasa',
 'Djibouti',
 'Egypt',
 'Ethiopia',
 'Gabon',
 'Gambia',
 'Ghana',
 'Guinea',
 'Guinea Bissau',
 'Ivory Coast',
 'Kenya',
 'Lesotho',
 'Liberia',
 'Libya',
 'Madagascar',
 'Malawi',
 'Mali',
 'Mauritania',
 'Mauritius',
 'Morocco',
 'Mozambique',
 'Namibia',
 'Niger',
 'Nigeria',
 'Other',
 'Rwanda',
 'Senegal',
 'Sierra Leone',
 'Somalia',
 'Somaliland',
 'South Africa',
 'South Sudan',
 'Sudan',
 'Swaziland',
 'Tanzania',
 'Togo',
 'Tunisia',
 'Uganda',
 'Western Sahara',
 'Zambia',
 'Zimbabwe',
]

const options = {
  subforums,
  submitOptions,
}

export default options;