(function() {
  const hideDropdown = function() {
    $(".dropdown-content").css("display", "none");
  };

  const toggleDropdown = function(e) {
    e.stopPropagation(); 
    let container = $(this).find(".dropdown-content");
    if(container.css("display") === "none") {
      container.css("display", "block");
    } else if (!$(this).hasClass("desktop-top-nav") && !$(this).hasClass("mobile-top-nav")) {
      hideDropdown();
    }
  };

  $(document).on("click", ".dropdown", toggleDropdown);

  $(document).on("click", ".desktop-top-nav .dropdown-content, .mobile-top-nav .dropdown-content", function(e) {
    e.stopPropagation();
  });

  $(document).on("click", ".mobile-top-nav .theme-switcher", function(e) {
      e.stopPropagation();
      $(".mobile-top-nav .dropdown-content").css("display", "none");
  });

  $(document).mouseup(function(e) {
    let container = $(".dropdown");
    if (!container.is(e.target) && container.has(e.target).length === 0) {
      hideDropdown();
    }
  });

  $(document).on('turbolinks:load', function() {
    $('.dropdown-content').hide();
  });
  
  $(window).on('pageshow', function(event) {
    $('.dropdown-content').hide(); 
  });
})();