$(window).on('pageshow', function(event) {
  console.debug("Page show event triggered");

  $('.rooms-list-page .chat-list ul a').on('click', function() {
      var scrollPosition = $('.rooms-list-page .chat-list ul').scrollTop();

      localStorage.setItem('chatListScrollPosition', scrollPosition);
      localStorage.setItem('navigatedToChatItem', 'true');
  });

  function hasNewMessages() {
    return localStorage.getItem('hasNewMessages') === 'true';
  }

  function restoreScrollPosition() {
    if (hasNewMessages()) {
        $('.rooms-list-page .chat-list ul').scrollTop(0);
    } else {
        var savedScrollPosition = localStorage.getItem('chatListScrollPosition');

        if (savedScrollPosition !== null) {
            if (localStorage.getItem('navigatedToChatItem') === 'true') {
                $('.rooms-list-page .chat-list ul').scrollTop(parseInt(savedScrollPosition, 10));
                localStorage.removeItem('chatListScrollPosition');
                localStorage.removeItem('navigatedToChatItem');
            }
        }
    }
  }

  // Check if page is loaded from cache
  if (event.persisted || (window.performance && window.performance.navigation.type == 2)) {
    restoreScrollPosition();
  }
});