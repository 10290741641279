(function () {
  const updateLastviewed = () => {
    $.ajax({
      url: '/notifications/new_notification_count',
      type: 'get',
      success: function (result) {
        console.debug('PROMISE', result);
      }
    });
  };

  const hideBadgeOnLoad = () => {
    if (window.location.pathname === '/notifications') {
      $('.notifications-badge').hide();
    }
  }

  $(document).on('click', '.dropdown.notifications-dropdown', updateLastviewed);
  $(document).on('turbolinks:load', hideBadgeOnLoad);
})();
