(function () {
  const scrollToBottom = () => {
    const messages = $('#messages');
    if (messages.length) {
      $('#messages').scrollTop($('#messages')[0].scrollHeight);
    }
  };

  $(document).on('turbolinks:load', scrollToBottom);
})();
