import consumer from './consumer';

class MessagesChannel {
  constructor(roomId) {
    this.roomId = roomId;
    this.subscription = null;
    this.connections = [];
    this.heartbeatInterval = null;

    // Bind the callback functions
    this.received = this.received.bind(this);
    this.connected = this.connected.bind(this);
    this.disconnected = this.disconnected.bind(this);
    this.startHeartbeat = this.startHeartbeat.bind(this);
    this.stopHeartbeat = this.stopHeartbeat.bind(this);
    this.sendHeartbeat = this.sendHeartbeat.bind(this);
  }

  connect() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    this.subscription = consumer.subscriptions.create({
      channel: "MessagesChannel",
      room: this.roomId
    },
    {
      received: this.received.bind(this),
      connected: this.connected.bind(this),
      disconnected: this.disconnected.bind(this),
    });
  }

  received(data) {
    let messagesContainer = document.getElementById('messages');
    const hash = window.location.hash;
    const highlightedItemId = hash ? hash.replace('#', '') : null;
    const chatListItemMessagesCount = document.querySelector(`.no-sidebar.messages-page .chat-list ul #chat-list-item-${highlightedItemId} .new-room-messages-count`);

    if(chatListItemMessagesCount) {
      chatListItemMessagesCount.classList.add("hidden");
    }

    const messageId = data.message_id; 
    const currentUserID = gon.current_user_id;
    if (messageId && messagesContainer && !document.getElementById(`message-id-${messageId}`)) {
      let newMessage = document.createElement('div');
      newMessage.id = `message-id-${messageId}`;

      if (data.sender === currentUserID) {
        newMessage.innerHTML = data.mine;
      } else {
        newMessage.innerHTML = data.theirs;
      }

      messagesContainer.appendChild(newMessage);
    }

    let isScrolledToBottom = messagesContainer.scrollTop + messagesContainer.clientHeight >= messagesContainer.scrollHeight;
    let scrollPosition = messagesContainer.scrollTop;

    if (data.sender === currentUserID || isScrolledToBottom) {
      messagesContainer.scrollTop = messagesContainer.scrollHeight;      
    } else {
      const bottomOfScroll = messagesContainer.scrollHeight - messagesContainer.clientHeight;
      const upperScrollThreshold = bottomOfScroll - 500;

      if (scrollPosition > upperScrollThreshold) {
        messagesContainer.scrollTop = messagesContainer.scrollHeight - messagesContainer.clientHeight;
      } else {
        messagesContainer.scrollTop = scrollPosition;
      }
      
    }
    
    document.getElementById('chat-text').value = '';

    const scrollToFirstListItem = () => {
      const $listContainer = $('.chat-list ul');
      // Scroll to the top of the list container
      
      $listContainer.scrollTop(0); 
    };
    
    scrollToFirstListItem()
  }

  connected() {
    const roomUuid = gon.room_uuid;

    if (gon.no_messages_list_item) {
      var chatListDiv = document.getElementsByClassName('chat-list')[0];
      var chatList = chatListDiv.getElementsByTagName('ul')[0];
    
      var newChatListItem = document.createElement('li');
      
      newChatListItem.innerHTML = gon.no_messages_list_item;
    
      newChatListItem.classList.add("selected-chat-list-item");
      newChatListItem.id = `room-${roomUuid}`;
    
      chatList.insertBefore(newChatListItem, chatList.firstChild);
      var firstListItem = chatList.getElementsByTagName('li')[1]; 
      if(firstListItem){
        firstListItem.classList.remove('selected-chat-list-item');
      }
    }

    $('#chat-text').focus();
    this.connections.push(this.roomId);

    this.startHeartbeat();
  }

  disconnected() {

    // Unsubscribe from the channel
    if (this.subscription) {
      consumer.subscriptions.remove(this.subscription);
      this.subscription = null;
    }

    const index = this.connections.indexOf(this.roomId);
    if (index > -1) {
      this.connections.splice(index, 1);
    }

    this.stopHeartbeat();
  }

  startHeartbeat() {
    this.heartbeatInterval = setInterval(this.sendHeartbeat, 5000);
  }

  stopHeartbeat() {
    console.log("STOPPED HEARTBEAT")
    clearInterval(this.heartbeatInterval);
  }

  sendHeartbeat() {
    if (this.subscription) {
      this.subscription.perform('heartbeat');
    }
  }
}

let messagesChannel;

document.addEventListener('turbolinks:load', () => {
  const messagesContainer = document.querySelector('#messages');

  if (messagesContainer && typeof gon !== 'undefined' && gon.room_uuid) {
    const roomId = gon.room_uuid;
    messagesChannel = new MessagesChannel(roomId);
    messagesChannel.connect();
  }
});

window.addEventListener('beforeunload', (e) => {
  if (messagesChannel) {
    console.log("Stopping heartbeat...");
    messagesChannel.stopHeartbeat();
  }
});

document.addEventListener("visibilitychange", () => {
  if (document.visibilityState === 'hidden') {
    console.log("Stopping heartbeat...");
    if(messagesChannel) {
      messagesChannel.stopHeartbeat();
    }
  } else {
    if(messagesChannel) {
      messagesChannel.startHeartbeat();
    }
  }
});