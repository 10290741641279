document.addEventListener('turbolinks:load', function() {
  fetchAndUpdateNotificationsCount();
  updateNotificationDisplay();
});

document.addEventListener('pageshow', function(event) {
  if (event.persisted || window.performance.navigation.type == 2) {
    fetchAndUpdateNotificationsCount();
    updateNotificationDisplay();
  }
});

function fetchAndUpdateNotificationsCount() {
  $.ajax({
      url: '/get_notifications_count',
      type: 'GET',
      success: function(data) {
          if (data.count > 0) {
              $('.notifications-badge').text(data.count).css("display", "flex");
          } else {
              $('.notifications-badge').hide(); 
          }
      },
      error: function() {
        console.error('Error fetching notifications count');
      }
  });
}

function updateNotificationDisplay() {
  $('#notification-spinner').show();
  $.ajax({
    url: '/notifications/current_states', 
    method: 'GET',
    dataType: 'json',
    success: function(data) {
      $('#notification-spinner').hide();
      data.unreadNotificationIds.forEach(function(id) {
        $(`#unread-blue-dot-${id}`).addClass('unread').show();
      });

      data.readNotificationIds.forEach(function(id) {
        $(`#unread-blue-dot-${id}`).removeClass('unread').hide();
      });
    },
    error: function(xhr, status, error) {
      console.error('Error fetching updated notification states:', error);
    }
  });
}