(function() {

  const clearMessageInput = function() {
    const submitMessage = () => {
      function isAndroid() {
        return /Android/i.test(navigator.userAgent);
      }

      function isPWA() {
        return (window.matchMedia('(display-mode: standalone)').matches) || (window.navigator.standalone);
      }

      if (!(isAndroid() && isPWA())) {
        $('#chat-text').on('keydown', function(event) {
          if (event.keyCode === 13 && !event.shiftKey) {
            event.preventDefault();
            $('#chat-text-button').click();
            $(this).val('');
          }
        });
      }
    }

    submitMessage() 
  }

  $(document).on('turbolinks:load', clearMessageInput);
})();