$(document).on('turbolinks:load', function() {
  function highlightRoom() {
      var hash = window.location.hash;
      var roomUuidFromUrl = hash ? hash.replace('#', '') : null;

      if (roomUuidFromUrl) {
          var targetItemId = "room-" + roomUuidFromUrl; 
          $('.selected-chat-list-item').removeClass('selected-chat-list-item');
          $('#' + targetItemId).parent('a').addClass('selected-chat-list-item');
      }
  }

  highlightRoom();
});