(function() {
  const googlePlayBadge = $(".google-play-badge");

  const hideBadge = function() {
    if (window.matchMedia('(display-mode: standalone)').matches) {
      $(".google-play-badge").css('display', 'none');
    }  
  }

  $(document).on("ready", hideBadge);
  $(document).on('turbolinks:load', hideBadge);
})();