document.addEventListener('turbolinks:load', function() {
  if (sessionStorage.getItem('profileUpdated') === 'true') {
    console.debug('Profile was updated. Refreshing data...');
    sessionStorage.removeItem('profileUpdated'); 
    updateProfileData(); 
  }
});

document.addEventListener('turbolinks:before-cache', function() {
  if (sessionStorage.getItem('profileUpdated') === 'true') {
    updateProfileData(); 
  }
});

function updateProfileData() {
  const userId = gon.current_user; 
  
  if (!userId) {
    console.error('User ID not found');
    return;
  }
  const cacheBuster = new Date().getTime();
  
  $.ajax({
    url: `/profiles/${userId}/updated_profile_data?_=${cacheBuster}`, 
    type: 'GET',
    dataType: 'json',
    success: function(data) {
      $('.bio-text').text(data.bio);
      $('.location-text').text(data.location);
    },
    error: function() {
      console.log('Error fetching profile data');
    }
  });
}