(function() {
    const followersTab = function() {
      $(".followers-tab ").css("border-bottom", "2px solid #EB4B4D");
      $(".following-tab").css("border-bottom", "0");
      if ($( ".followers-tab" ).hasClass("deselected")) {
        $(".followers-tab").removeClass("deselected").addClass("selected");
        $( ".following-tab" ).removeClass( "selected" ).addClass("deselected");
      }
    };

    const followingTab = function() {
      $(".following-tab").css("border-bottom", "2px solid #EB4B4D");
      $(".followers-tab").css("border-bottom", "0");
      if ($( ".following-tab" ).hasClass("deselected")) {
        $(".following-tab").removeClass("deselected").addClass("selected");
        $( ".followers-tab" ).removeClass( "selected" ).addClass("deselected");
      }
    };

    const userProfileLink = function() {
      var username = $(this).data('username');
      location.assign('/user/'+ username);
    };

    const followFormStopPrapagation = function(e) {
       e.stopPropagation();
    };

  $(document).on("click", ".followers-tab a", followersTab);
  $(document).on("click", ".following-tab a", followingTab);
  $(document).on("click", ".profile-user-follower", userProfileLink);
  $(document).on("click", ".follow_form", followFormStopPrapagation);
})();