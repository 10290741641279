(function() {
  const hide = true;

  const hideComment = function() {
    const commentId = $(this).data('comment-id');
    $(`#comment-${commentId} .body`).css('display', 'none');
  }

  const showComment = function() {
    $(".engagements").show();
    $(".body").show();

    $(this).closest(".comment-node").find(".comment-tools").show();
  }

  const hideReplyButton = function() {
    const commentId = $(this).data('comment-id');
    $(`#reply-form-${commentId}`).hide();
  }

  const cancelReply = function() {
    const replyForm = $(this).closest(".comment-node").find(".reply-form");

    replyForm.hide();
  }

  const cancelEdit = function() {
    showComment();
    const editForm = $(this).closest(".comment-node").find(".edit-form")
    
    $(this).closest(".comment-node").find(".comment-tools").show();
    editForm.hide();
  }

  const hideDropdownTools = function() {
    $(this).closest(".dropdown-tools").hide();
    $(this).closest(".comment-tools").hide();
  }

  // Link Dropdown Tools

  const hideLinkDropdownTools = function() {
    $(".link-show-page-main-info .dropdown-tools").hide();
    $(".link-body").hide();
  }

  const cancelEditLink = function() {
    $(".edit-link-form").hide();
    $(".link-body").show();
  }



  $(document).on("click", '.comment .edit-link-tool', hideComment);
  $(document).on("click", '.cancel-edit-button', cancelEdit);
  $(document).on("click", '.edit-link-form .cancel-edit-button', cancelEditLink);
  $(document).on("click", '.cancel-reply-button', cancelReply);
  $(document).on("click", '.save-comment', showComment);
  $(document).on("click", '.delete-link', hideComment);
  $(document).on("click", '.comment .edit-link-tool', hideDropdownTools);
  $(document).on("click", '.delete-link', hideDropdownTools);
  $(document).on("click", '.image-link-layout .edit-link-tool', hideLinkDropdownTools);
})();