(function() {
  function isAndroid() {
    return /Android/i.test(navigator.userAgent);
  }

  function isPWA() {
    return (window.matchMedia('(display-mode: standalone)').matches) || (window.navigator.standalone);
  }

  const hideMessagesButton = () => {
    const messagesButton = $(".profile-message-button-wrapper");
    if (isAndroid() && isPWA() || ($(window).innerWidth() >= 619)) {
      messagesButton.css('display', 'block');
    } else {
      messagesButton.css('display', 'none');
    }
  }
  const hideMobileSubmitButton = () => {
    const mobileSubmitButton = $(".mobile-top-nav.top-nav .top-right .submit-link");
    if (isAndroid() && isPWA() || ($(window).innerWidth() >= 619)) {
      mobileSubmitButton.css('display', 'none');
    } else {
      mobileSubmitButton.css('display', 'block');
    }
  }

  $(document).on('turbolinks:load', hideMessagesButton);
  $(document).on('turbolinks:load', hideMobileSubmitButton);
})();