import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    console.log("Share controller connected");
  }

  share(event) {
    event.preventDefault();

    const url = event.currentTarget.getAttribute("data-mobile-share-url");
    const title = event.currentTarget.getAttribute("data-mobile-share-title");

    if (navigator.share) {
      navigator.share({
        url: url,
        title: title,
      }).then(() => {
        console.log('Successful share');
      }).catch((error) => {
        console.log('Error sharing:', error);
      });
    } else {
      console.debug('Web Share API is not supported in your browser.');
      alert('Web Share API is not supported in your browser. Please use the traditional share options.');
    }
  }

}
