(function() {
  const MOBILE_BREAKPOINT = 1200;
  
  let showSidebar = false;
  let isAnimating = false;
  let lastScrollPosition = 0;
  let isNavigatingViaSidebar = false;

  const getTurbolinks = () => {
    if (typeof Turbolinks !== 'undefined') {
      return Turbolinks;
    } else if (typeof require !== 'undefined') {
      try {
        return require('turbolinks');
      } catch (e) {
        console.error('Failed to load Turbolinks:', e);
      }
    }
    return null;
  };

  const turbolinks = getTurbolinks();

  function isMobile() {
    return $(window).innerWidth() <= MOBILE_BREAKPOINT;
  }

  const toggleSidebar = function(event) {
    if (isAnimating) return;
    isAnimating = true;
    
    showSidebar = !showSidebar;
    updateSidebarState();
    
    setTimeout(() => {
      isAnimating = false;
    }, 250);
  };

  const updateSidebarState = function() {
    if (showSidebar) {
      $("#overlay").css("display", "block");
      $("#mobile-sidebar").animate({left: "0"}, {duration: 150, easing: "linear"});
      $("#mobile-sidebar-wrapper").animate({left: "0"}, {duration: 150, easing: "linear"});
    } else {
      $("#overlay").css("display", "none");
      $("#mobile-sidebar").animate({left: "-300px"}, {duration: 200, easing: "linear"});
      $("#mobile-sidebar-wrapper").animate({left: "-300px"}, {duration: 200, easing: "linear"});
    }
  };

  const hideSidebarWithOverlay = function(e) {
    showSidebar = false;
    updateSidebarState();
  };

  const hideMobileSidebar = function(e) {
    if (isMobile()) {
      e.preventDefault();
      lastScrollPosition = window.pageYOffset;
      isNavigatingViaSidebar = true;
      
      showSidebar = false;
      updateSidebarState();

      setTimeout(() => {
        if (turbolinks) {
          turbolinks.visit(e.currentTarget.href);
        } else {
          window.location = e.currentTarget.href;
        }
      }, 200); 
    }
  };

  const checkPosition = function() {
    if ($(window).innerWidth() >= MOBILE_BREAKPOINT) {
      $("#mobile-sidebar").css({left: "-300px"});
      $("#mobile-sidebar-wrapper").css({left: "-300px"});
      $("#mobile-menu-icon").css("display", "none");
      $("#overlay").css("display", "none");
      showSidebar = false;
    } else {
      $("#mobile-menu-icon").css("display", "flex");
    }
    updateSidebarState();
  };

  const initSidebar = function() {
    checkPosition();
    $(document).off("click", ".menu-icon").on("click", ".menu-icon", toggleSidebar);
    $(document).off("click", ".sidebar-close-button").on("click", ".sidebar-close-button", toggleSidebar);
    $(document).off("click", "#overlay").on("click", "#overlay", hideSidebarWithOverlay);
    $(window).off("resize", checkPosition).on("resize", checkPosition);
    $(document).off("click", ".cat-link").on("click", ".cat-link", hideMobileSidebar);
  };

  if (turbolinks) {
    document.addEventListener('turbolinks:before-visit', function() {
      if (isMobile() && isNavigatingViaSidebar) {
        turbolinks.clearCache();
      }
    });

    document.addEventListener('turbolinks:load', function() {
      if (!isMobile()) return;

      if (isNavigatingViaSidebar) {
        console.log('Scrolling to top');
        window.scrollTo(0, 0);
        isNavigatingViaSidebar = false;
      } else if (performance.navigation.type === performance.navigation.TYPE_BACK_FORWARD) {
        window.scrollTo(0, lastScrollPosition);
      }
      
    });
  }

  $(document).on("turbolinks:load", initSidebar);
  $(document).ready(initSidebar);

  $(document).on("turbolinks:before-cache", function() {
    showSidebar = false;
    $("#mobile-sidebar").css({left: "-300px"});
    $("#mobile-sidebar-wrapper").css({left: "-300px"});
    $("#overlay").css("display", "none");
  });

  if (isMobile() && turbolinks && turbolinks.scroll) {
    turbolinks.scroll = { top: false, left: false };
  }
})();