(function() {
  const toggleElementsBasedOnDevice = () => {
    const isMobile = /Mobi|Android/i.test(navigator.userAgent);
    const isStandalone = window.matchMedia('(display-mode: standalone)').matches;

    const bottomNavFrontPages = $('#bottom-nav-front-pages');
    const bottomNavOtherPages = $('#bottom-nav-other-pages')
    
    if (isMobile && isStandalone ) {
      // PWA Only
      bottomNavFrontPages.css('display', 'flex');
      bottomNavOtherPages.css('display', 'flex');
    }
  };

  $(document).on('turbolinks:load', toggleElementsBasedOnDevice);
  $(window).on('resize', toggleElementsBasedOnDevice);
})();