import { isProduction } from './utils/environment';

function suppressConsoleMethods() {
  if (isProduction()) {
    const noop = () => {};
    console.log = noop;
    console.warn = noop;
    console.error = noop;
    console.info = noop;
    console.debug = noop;
  }
}

suppressConsoleMethods();