(function() {
    const showLinkPost= function() {
      $("#link-post").show();
      $("#image-post").hide();
      $("#story-post").hide();

      $("#link-post-tab").css("border-bottom", "1px solid #EB4B4D");
      $("#story-post-tab").css("border-bottom", "0");
      $("#image-post-tab").css("border-bottom", "0");
      $("#link-post-tab").removeClass("tab-deselected").addClass("tab-selected");
      $("#story-post-tab" ).removeClass( "tab-selected" ).addClass("tab-deselected");
      $("#image-post-tab" ).removeClass( "tab-selected" ).addClass("tab-deselected");
    }

    const showStoryPost = function() {
      $("#story-post").show();
      $("#image-post").hide();
      $("#link-post").hide();

      $("#story-post-tab").css("border-bottom", "1px solid #EB4B4D");
      $("#link-post-tab").css("border-bottom", "0");
      $("#image-post-tab").css("border-bottom", "0");
      $("#story-post-tab").removeClass("tab-deselected").addClass("tab-selected");
      $("#link-post-tab" ).removeClass( "tab-selected" ).addClass("tab-deselected");
      $("#image-post-tab" ).removeClass( "tab-selected" ).addClass("tab-deselected");
    }

    const showImagePost = function() {
      $("#image-post").show();
      $("#story-post").hide();
      $("#link-post").hide();

      $("#image-post-tab").css("border-bottom", "1px solid #EB4B4D");
      $("#link-post-tab").css("border-bottom", "0");
      $("#story-post-tab").css("border-bottom", "0");
      $("#image-post-tab").removeClass("tab-deselected").addClass("tab-selected");
      $("#link-post-tab" ).removeClass( "tab-selected" ).addClass("tab-deselected");
      $("#story-post-tab" ).removeClass( "tab-selected" ).addClass("tab-deselected");
    }

    $(document).on("click", "#link-post-tab", showLinkPost);
    $(document).on("click", "#image-post-tab", showImagePost);
    $(document).on("click", "#story-post-tab", showStoryPost);
})();