(function () {
  $(document).on('turbolinks:load', function() {
    if (window.location.pathname.startsWith('/messages') && 'virtualKeyboard' in navigator) {
      // Opt in to the new virtual keyboard behavior
      navigator.virtualKeyboard.overlaysContent = true;
    
      let keyboardOpen = false;

      navigator.virtualKeyboard.addEventListener('geometrychange', () => {
        const {height} = navigator.virtualKeyboard.boundingRect;
        keyboardOpen = height > 0;
      });

      $('.msg-form').on('submit', function(e) {
        e.preventDefault(); 
        if (keyboardOpen) {
          $('textarea').focus();
        }
      });
    }
  });
})();
