(function() {
  const imagePreview = function(input, selector) {
    if (input.files && input.files[0]) {
      const reader = new FileReader();
      reader.onload = function (e) {
        const imgTag =  `<img src="${e.target.result}" />`;
        $(imgTag).insertBefore(selector);
      };
      reader.readAsDataURL(input.files[0]);
    }
  }

  $(document).on("change", "#profile-pic-uploader #imgInp", function() {
    $('.default-profile-image').hide();
    $('#profile-pic-uploader img').remove();
    imagePreview(this, '#profile-pic-uploader');
  });

  $(document).on("change", " #imgInp", function() {
    $('#story-image-uploader img').remove();
    imagePreview(this, '#story-image-uploader .submit-link-field');
  });
})();