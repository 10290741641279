(function () {
  const tabs = ["link", "image", "story"];

  const selectTab = (tab) => {
    const otherTabs = tabs.filter((t) => t !== tab);
    otherTabs.forEach(hideTab);
    showTab(tab);
  };

  const showTab = (tab) => {
    const $tab = $(`#${tab}-post-tab`);
    $(`#${tab}-post`).show();
    $tab.css("border-bottom", "2px solid #EB4B4D");
    $tab.removeClass("tab-deselected").addClass("tab-selected");
  };

  const hideTab = (tab) => {
    const $tab = $(`#${tab}-post-tab`);
    $(`#${tab}-post`).hide();
    $tab.css("border-bottom", "0");
    $tab.removeClass("tab-selected").addClass("tab-deselected");
  };
  const showLinkPost = () => {
    selectTab("link");
  };

  const showStoryPost = () => {
    selectTab("story");
  };

  const showImagePost = () => {
    selectTab("image");
  };

  const clearImageInput = () => {
    $("#story-image-uploader img").remove();
    $("#imgInp").val("");
  };

  const clearLinkInput = () => {
    $("#url-input").val("");
  };

  const clearStoryInput = () => {
    $("#link_body").val("");
  };

  const clearUnusedInputs = (event) => {
    const selectedTabId = $('.tab-selected')[0].id;
    const tab = /(\w+)-*/.exec(selectedTabId)[1];

    if (tab === 'link') {
      clearStoryInput();
      clearImageInput();
    } else if (tab === 'story') {
      clearLinkInput();
      clearImageInput();
    } else if (tab === 'image') {
      clearStoryInput();
      clearLinkInput();
    }
  }

  $(document).on("click", "#link-post-tab", showLinkPost);
  $(document).on("click", "#image-post-tab", showImagePost);
  $(document).on("click", "#story-post-tab", showStoryPost);
  $(document).on('submit', '#new_link', clearUnusedInputs);
})();