(function () {
  const excludedPatterns = [/\/submit$/];

  const shouldSkipPage = (url) => {
    return excludedPatterns.some((pattern) => pattern.test(url));
  };

  const navigateBackSkippingPages = () => {
    if (window.history.length > 1) {
      history.back();

      setTimeout(() => {
        const currentUrl = window.location.pathname;

        if (shouldSkipPage(currentUrl)) {
          navigateBackSkippingPages();
        }
      }, 100); 
    } else {
      window.location.href = '/';
    }
  };

  const handleBackButtonClick = (event) => {
    event.preventDefault();
    navigateBackSkippingPages();
  };

  const initializeBackButton = () => {
    $(document).off('click', '.go-back-button');
    $(document).on('click', '.go-back-button', handleBackButtonClick);
  };

  $(document).on('turbolinks:load', initializeBackButton);
})();
