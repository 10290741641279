(function() {
  const toggleNavBasedOnDevice = () => {
    const isMobile = /Mobi|Android/i.test(navigator.userAgent);
    const isStandalone = (window.matchMedia('(display-mode: standalone)').matches) || (window.navigator.standalone) || document.referrer.includes('android-app://');
    const isWithinWidth = $(window).width() <= 640;

    const linkShowPageTopNav = $('.top-nav-wrapper.link-show-page-top-nav');
    const submitPageTopNav = $('.top-nav-wrapper.submit-page-top-nav');
    const subforumSubmitTopNav = $('.subforum-submit .top-nav-wrapper');
    const profilePageTopNav = $('.profile-page .top-nav-wrapper');
    const pwaNav = $('.pwa-nav');
    const linkShowPageSubListing = $('.sub-listing.link-show-page-sub-listing ')
    const signinSignupTopNav = $('.top-nav.signin-signup-top-nav');
    const searchPageTopNav = $('.pwa-nav.search-page')
    const searchPageTopNavWrapper = $(".search-page .top-nav-wrapper")

    if (isMobile && isStandalone && isWithinWidth) {
      pwaNav.show();
      linkShowPageSubListing.hide();
      searchPageTopNav.show();
    } else {
      linkShowPageTopNav.show();
      profilePageTopNav.show();
      subforumSubmitTopNav.show();
      submitPageTopNav.show();
      linkShowPageSubListing.show();
      searchPageTopNavWrapper.show();
      signinSignupTopNav.css("display", "flex");
    }
  };

  $(document).on('turbolinks:load',toggleNavBasedOnDevice);
  $(window).on('resize', toggleNavBasedOnDevice);
})();