$(document).on('turbolinks:load', function() {
  function applyTheme() {
    const theme = localStorage.getItem('theme');
    const themeColorMeta = $("#theme-color-meta");
    
    if (theme === 'darkmode') {
      $('html').addClass('darkmode');
      $('.logo .logo-darkmode').show(); 
      $('.logo .logo-lightmode').hide(); 
      $('.no-text-logo .logo-darkmode, .no-text-logo .logo-darkmode__mobile').show(); 
      $('.no-text-logo .logo-lightmode, .no-text-logo .logo-lightmode__mobile').hide(); 
      $('.sidebar-logo .logo-darkmode').show(); 
      $('.sidebar-logo .logo-lightmode').hide();
      $(".darkmode .submit-group .chat-text-wrapper").css('background-color', '#272729');

      // Update the status bar color for dark mode
      if (themeColorMeta.length) {
        themeColorMeta.attr('content', '#1d1d1e');
      }
    } else {
      $('html').removeClass('darkmode');
      $('.logo .logo-lightmode').show(); 
      $('.logo .logo-darkmode').hide();
      $('.no-text-logo .logo-lightmode, .no-text-logo .logo-lightmode__mobile').show(); 
      $('.no-text-logo .logo-darkmode, .no-text-logo .logo-darkmode__mobile').hide();
      $('.sidebar-logo .logo-lightmode').show(); 
      $('.sidebar-logo .logo-darkmode').hide();
      $(".submit-group .chat-text-wrapper").css('background-color', '#f1f0f0');
      
      // Update the status bar color for light mode
      if (themeColorMeta.length) {
        themeColorMeta.attr('content', '#FBFBFB'); 
      }
    }
  }

  applyTheme();

  $('.theme-switcher').off('click').on('click', function() {
    const currentTheme = localStorage.getItem('theme');
    const newTheme = currentTheme === 'darkmode' ? 'lightmode' : 'darkmode';

    localStorage.setItem('theme', newTheme);

    applyTheme();
  });

  $(document).on("click", "#sign-out-link", function() {
    localStorage.removeItem('theme');
  });
});