import 'core-js/stable';
import 'regenerator-runtime/runtime';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import '../toastr_config';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage, deleteToken, isSupported } from 'firebase/messaging';
require('channels');
import "../suppress_console";

import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';
import $ from 'jquery';

Turbolinks.start();

document.addEventListener('turbolinks:load', () => {
  if (!window.Rails) {
    Rails.start();
    window.Rails = Rails;
  }
});

window.$ = $;
window.jQuery = $;
window.toastr = toastr

import "../stimulus_setup";
import "../lib/custom";
import '../stylesheets/custom_toastr.scss';
import initializeReactApps from '../react_select_initializer';

initializeReactApps();

document.addEventListener('turbolinks:load', function() {
  const homeTab = document.getElementById('home-tab-bottom-nav');

  if (homeTab) {
    homeTab.addEventListener('click', function(event) {
      event.preventDefault();
      const homeUrl = homeTab.getAttribute('href');
      Turbolinks.visit(homeUrl, { replace: true });
    });
  }
});

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.addEventListener('message', function(event) {
    if (event.data && event.data.type === 'navigate') {
      // Navigate to the new URL only if it differs from the current one
      if (window.location.href !== event.data.url) {
        if (typeof Turbolinks !== 'undefined') {
          Turbolinks.visit(event.data.url);
        } else {
          history.pushState(null, '', event.data.url);
          window.dispatchEvent(new PopStateEvent('popstate'));
        }
      }
    }
  });
}

// PUSH NOTIFICATIONS
const app = initializeApp(window.firebaseConfig);
let currentToken = null;
let isButtonClicked = false;
let messaging = null;
const deviceId = getDeviceId(); 

async function handleLogout() {
  if (isSupported() && 'serviceWorker' in navigator) {
      try {
          await removePushToken();
      } catch (error) {
          console.debug('Error handling push token during logout:', error);
      }
  }
  $('.push-notifications .switch, .push-notifications .slider').removeClass('on').addClass('off');
  await logoutUser();
}

$(document).on("click", "#sign-out-link", async function() {
  await handleLogout();
  window.location.href = '/trending';
});

if (isSupported() && 'serviceWorker' in navigator) {
    const app = initializeApp(firebaseConfig);
    messaging = getMessaging(app);

    $(document).on('turbolinks:load', function() { 
      (async function() { 
        onMessage(messaging, (payload) => {
            console.debug("Foreground notification received:", payload);
        });
      })(); 
    });

    $(document).off("click", ".push-notifications .switch-container").on("click", ".push-notifications .switch-container", async function() {
      if (isButtonClicked) return;  
      isButtonClicked = true;       
  
      const isCurrentlyEnabled = $('.push-notifications .switch').hasClass('on');
      const deviceHasPermission = Notification.permission === 'granted';
  
      try {
        if (!isCurrentlyEnabled) {
            if (deviceHasPermission) {
                await createTokenAndStore();
                enableNotificationsForUser();
            } else {
                const permissionGranted = await requestNotificationPermission();
                if (permissionGranted) {
                    await createTokenAndStore();
                    enableNotificationsForUser();
                }
            }
        } 
        else {
            disableNotificationsForUser();
        }
      } catch (error) {
          console.error("Error in toggle notification:", error);
      } finally {
          isButtonClicked = false;  
      }
    });
} else {
  console.debug("Firebase Cloud Messaging is not supported in this environment.");
}

async function registerDeviceToken() {

    if (!isSupported()) {
        console.error("Firebase messaging is not supported by this browser.");
        return;
    }

    try {
        const messaging = getMessaging();
        const deviceId = getDeviceId();
        const fcmToken = await getToken(messaging);

        if (fcmToken) {
            await sendTokenToServer(fcmToken, deviceId);
        } else {
            console.error("No token received from Firebase.");
        }
    } catch (error) {
        console.error("Error fetching FCM token:", error);
    }
}

async function sendTokenToServer(token, deviceId) {
  await fetch('/register_token', {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') 
      },
      body: JSON.stringify({ token: token, device_id: deviceId })
  });
}

window.registerDeviceToken = registerDeviceToken;

function getDeviceId() {
  let deviceId = localStorage.getItem('device_id');

  if (!deviceId) {
      deviceId = generateUUID();
      localStorage.setItem('device_id', deviceId);
  }

  return deviceId;
}

function generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
  });
}

async function updateNotificationToggleUI() {
  try {
      const deviceHasPermission = Notification.permission === 'granted';
      const deviceId = getDeviceId(); 

      const tokenResponse = await $.get('/check_token_presence', { device_id: deviceId });
      const tokenExistsForDevice = tokenResponse.tokenExists;

      const preferenceResponse = await fetch('/push_notifications_enabled');
      const preferenceData = await preferenceResponse.json();
      const userPrefersNotifications = preferenceData.enabled;

      if (tokenExistsForDevice && deviceHasPermission && userPrefersNotifications) {
        $('.push-notifications .switch, .push-notifications .slider').removeClass('off').addClass('on');
      } else {
        $('.push-notifications .switch, .push-notifications .slider').removeClass('on').addClass('off');
      }
  } catch (error) {
      console.error('Error updating notification toggle UI:', error);
  }
}

$(document).on('turbolinks:load', function() {
  const getCurrentUser = (typeof gon !== 'undefined' && gon.current_user) ? gon.current_user : null;
  
  if(getCurrentUser) {
    updateNotificationToggleUI();
  }
});

async function requestNotificationPermission() {
  try {
      const permission = await Notification.requestPermission();
      return permission === 'granted';
  } catch (error) {
      console.debug('Error requesting notification permission:', error);
      return false;
  }
}


function disableNotificationsForUser() {
  $.ajax({
    url: '/disable_push_notifications', 
    method: 'POST',
    headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
  }).done(() => {
    $('.push-notifications .switch, .push-notifications .slider').removeClass('on').addClass('off');
  }).fail((jqXHR, textStatus, errorThrown) => {
    console.debug('Error disabling notifications:', textStatus, errorThrown);
  });
}

async function enableNotificationsForUser() {
  try {
      const response = await $.ajax({
          url: '/enable_push_notifications',
          method: 'POST',
          headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      });

      console.debug("Notifications enabled for the user.");
      $('.push-notifications .switch, .push-notifications .slider').removeClass('off').addClass('on');

      if (Notification.permission === 'granted') {
          try {
              const currentToken = await getToken(messaging);
          } catch (error) {
              console.debug("Error getting token:", error);
          }
      } else {
          const permissionGranted = await requestNotificationPermission();
          if (permissionGranted) {
            const newToken = await getToken(messaging);
          }
      }

  } catch (error) {
      console.debug('Error enabling notifications:', error);
  }
}

async function createTokenAndStore() {
  try {
    const token = await getToken(messaging);
    await $.ajax({
      url: '/notification_tokens',
      method: 'POST',
      data: { notification_token: { token: token, device_id: deviceId, platform: 'fcm' } },
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
    });
    $('.push-notifications .switch, .push-notifications .slider').removeClass('off').addClass('on');
  } catch (error) {
    console.debug('Error saving token:', error);
  }
}

async function removePushToken() {
  if (!messaging) {
    console.debug('Firebase messaging is not initialized.');
    return;
  }

  try {
      const currentToken = await getToken(messaging);
      if (currentToken) {
          await deleteToken(messaging, currentToken);
          console.debug("Token removed from server.");
      }
  } catch (error) {
      console.debug('Error handling push token during logout:', error);
  }
}

async function logoutUser() {
    try {
        await $.ajax({
            url: '/signout',
            method: 'DELETE',
            data: { device_id: deviceId },
            headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
        });
        console.debug("User logged out successfully.");
        return true;
    } catch (error) {
        console.debug('Error during user logout:', error);
        return false;
    }
}
