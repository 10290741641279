(function () {
  function setCookie(name, value) {
    document.cookie = name + '=' + value + '; path=/';
  }

  function getCookie(name) {
    var cookieValue = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)');
    return cookieValue ? cookieValue.pop() : '';
  }
  const toggleSelectedClassOnClick = () => {
    var selected = getCookie('selectedItem');
    if (selected) {
      $('.messages-page .chat-list ul a li').eq(selected).addClass('selected-chat-list-item');
    } else {
      $('.messages-page .chat-list ul a:first-child li').addClass('selected-chat-list-item');;
    }

    $(document).on("click", ".messages-page .chat-list ul a", function() {
      var selectedIndex = $(this).index();
      setCookie('selectedItem', selectedIndex);
      console.debug(selected)
    });
  };
  
  $(document).on('turbolinks:load', toggleSelectedClassOnClick);

})();