import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import options from '../data/submit-options-data';

function App() {
  const COUNTRY_REGEX = /a\/([a-zA-Z-]+)\/?/;
  const IMAGE_EXTENSIONS = {
    'africa': 'jpg',
    'other': 'jpg'
  };

  const submitOptions = options.submitOptions.map((opt, i) => {
    const slug = opt.toLowerCase().replace(/\s+/, '-');
    const extension = IMAGE_EXTENSIONS[slug] || 'svg';
    
    return {
      value: i,
      label: opt,
      img: `/assets/images/flags/${slug}.${extension}`
    };
  });

  const [defaultOption, setDefaultOption] = useState(null);

  function countryFromPath(path) {
    const match = COUNTRY_REGEX.exec(path);
    return match ? match[1] : '';
  }

  function updateDefaultOption() {
    const countryPath = countryFromPath(location.pathname);
    
    if (!countryPath) {
      setDefaultOption(null);
      return;
    }

    let selectedCountry, selectedValue, defaultImg;

    if (countryPath === 'Car') {
      selectedCountry = "CAR";
    } else {
      selectedCountry = countryPath.replace(/-/g, " ").split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
    }


    const slug = countryPath.toLowerCase();
    const extension = IMAGE_EXTENSIONS[slug] || 'svg';
    selectedValue = options.subforums[countryPath].id;
    defaultImg = `/assets/images/flags/${slug}.${extension}`;
    

    setDefaultOption({
      label: selectedCountry,
      value: selectedValue,
      img: defaultImg
    });
  }

  useEffect(() => {
    updateDefaultOption();

    const handlePopState = () => { 
      updateDefaultOption();
    };

    window.addEventListener('popstate', handlePopState);

    return () => window.removeEventListener('popstate', handlePopState);
  }, []);

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function handleChange(e) {
    const country = e.label.toLowerCase().replace(/\s+/, '-');
    const capitalizedCountry = capitalizeFirstLetter(country);

    location.assign(`/a/${capitalizedCountry}/submit`);
    
  }

  return (
    <>
      <Select 
        options={submitOptions} 
        placeholder="Choose a Forum" 
        name="link[sub_forum_id]" 
        id="link_sub_forum_id" 
        onChange={handleChange}  
        value={defaultOption}
        className="react-select-submit-options"
        classNamePrefix="react-select"
        formatOptionLabel={country => (
          <>
            <img src={country.img} alt="country-image" className="subforum-option__image"/>
            <span>{country.label}</span>
          </>
        )}
      />
    </>
  );
}

export default App;