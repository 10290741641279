const isMobile = /Mobi|Android/i.test(navigator.userAgent);
const isStandalone = (window.matchMedia('(display-mode: standalone)').matches) || (window.navigator.standalone) || document.referrer.includes('android-app://');


window.addEventListener('popstate', function(event) {
  if (event.state && event.state.profileUpdated) {
    updateProfileData();
  }
});

$(document).on('turbolinks:load', function() {
  if (sessionStorage.getItem('profileUpdated') === 'true') {
    updateProfileData();
  }
});

$(document).on('click', '.feed-posts-comments-tabs .feed-posts-content .link a', function(e) {
  e.preventDefault();

  var postUrl = $(this).attr('href');
  var currentProfileUrl = window.location.href;

  if (!history.state || !history.state.fromProfile || !history.state.profileUpdated) {
    history.pushState({fromProfile: true, profileUpdated: sessionStorage.getItem('profileUpdated')}, '', currentProfileUrl);
  }
  window.location.href = postUrl;
});

const currentUserId = gon.current_user;
const userId = gon.profile_user_id;

function showPlaceholders() {
  const profileContentId = "#profileContent_user_" + currentUserId;

  $(`${profileContentId} .bio-text, ${profileContentId} .location-text`).each(function() {
    $(this).html('<div class="spinner"></div>');
  });

  $(`${profileContentId} .profile-pic-main, ${profileContentId} .profile-pic-placeholder`).hide();
  $(`${profileContentId} .profile-image`).html('<div class="spinner"></div>');
  $(`${profileContentId} .profile-pic-main`).show(); 
}

function updateProfileDataForCurrentUser(data) {
  const locationTextHtml = `<i class='fas fa-map-marker-alt'> <span>${data.location}</span></i>`
  $('.bio-text').text(data.bio);
  $('.location-text').html(locationTextHtml);
  sessionStorage.setItem('profileUpdated', 'true');

  if (data.has_image) {
    var imgElement = $('.profile-pic-main img');
    if (imgElement.length === 0) {
      $('.profile-pic-placeholder').hide(); 
      $('.profile-pic-main').html($('<img>', { src: data.image_url, alt: 'Profile Image' })).show();
    } else {
      imgElement.attr('src', data.image_url);
    }
  } else {
    $('.profile-pic-main').hide();
    $('.profile-pic-placeholder').show();
  }
}

function updateProfileData() {

  if (!userId) {
    console.error('User ID not found');
    return;
  }
  
  showPlaceholders(currentUserId);
  const cacheBuster = new Date().getTime();

  $.ajax({
    url: `/profiles/${userId}/updated_profile_data?_=${cacheBuster}`, 
    type: 'GET',
    data: { user_id: userId },
    dataType: 'json',
    success: function(response) {
      if (response.user_id == currentUserId) {
        updateProfileDataForCurrentUser(response);
      }
    },
    error: function() {
      console.log('Error fetching profile data');
    }
  });
}