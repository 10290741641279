import axios from 'axios';

const subforumCategories = `/options`;
const categories = '/options/categories';

axios.defaults.withCredentials = true;

const api = {
  subforumCategories,
  categories,
};

export default api;
