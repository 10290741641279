(function () {
  var deferredPrompt;

  $(window).on('beforeinstallprompt', function (e) {
    console.debug('beforeinstallprompt Event fired');
    e.preventDefault();

    // Stash the event so it can be triggered later.
    deferredPrompt = e.originalEvent;

    return false;
  });
})();
